.cart {
  background-image: url("../../assets/images/background.jpg");
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 50px;
  background-position: center;
}

.cart .favTitle {
  margin: 20px 0px;
}
.cart .favImg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.cartHole{
  display: flex;
  justify-content: center;
  width: 100%;
}

/* singleItme */
.cart .singleItme {
  padding: 1%;
  border-radius: 10px;
}

.cart .favDetails {
  height: 100%;
  margin: auto 0;
  padding: 10px; 
}
.cart .favDetails p{
  margin: 0px;
  margin-bottom: 5px;
}

.cart .favDetails .small {
  font-size: 14px;
  font-weight: bold;
}
.cart .favDetails .midium {
  font-size: 20px;
  font-weight: bold;
}

.cart .favDetails b {
  padding: 4px 10px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .cart {
    padding: 5px;
  }
}
.addressForm{
    background-image: url("../../assets/images/background.jpg");
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
   padding: 50px 0px;

}
.addressFormContainer{
    max-width: 570px;
    padding: 30px;
    margin: auto;
}
.rowContainer{
    display: flex;
  }
  .rowContainer .rowItem{
    margin-right: 15px;
    margin-top: 15px;
  }
  .rowContainer .rowItem label{
    font-size: 16px;
    font-weight: 500;
  }
  .rowContainer .rowItem input{
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    width: 250px;
    padding: 10px;
    height: 20px;
    border-radius: 5px;
    background-color: #EEEEEE;
    border: 1px solid black;
   }

   .frombutton{
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    background-color: #080808;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 22px;
    margin: 20px 0px;
    margin-right: 20px;
    text-transform: uppercase;
  }


  .profile{
    width: 500px;
  }
  @media only screen and (max-width: 999px) {
    .profile{
        width: 100%;
    }
  }

  @media only screen and (max-width: 660px) {
    .addressForm{
        padding: 0px;
    }
    .addressFormContainer{
        min-height: 100vh;
        display: flex;
        justify-content: center;
    }
    .rowContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
  }
.payment {
  background-image: url("../../assets/images/background.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  background-position: center;
}
.payment .main {
  width: 70%;
  margin: 0 auto;
}
.payment .main .favTitle {
  font-family: 'Bebas Neue';
  font-weight: 400;
}

.payment .checkbox {
  padding: 10px;
  margin: 10px 0px;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 678px) {
  .payment .checkbox  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .payment .main {
    width: 100%;
  }
}

.checkbox .images {
  display: flex;
}
.checkbox .images img {
  width: 50px;
  object-fit: contain;
  margin-right: 10px;
}

.terms p {
  margin: .25rem 0;
}
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
body {
  margin: 0;
  font-family: 'Bebas Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Bebas Neue';
}

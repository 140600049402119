/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");

:root {
  --metrofamily: "Montserrat", sans-serif;
} */

.mainsection {
  background-image: url("../../assets/images/background.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  overflow: auto;
  background-position: center;
}

.mainsection .favTitle {
  font-family: 'Bebas Neue';
  font-weight: 400;
}

.profileNav{
  padding: 10px 30px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(92, 92, 92, 0.25);
  border-radius: 10px;
  margin-bottom: 20px;
}

.profileNav p {
  padding: 10px;
  border-radius: 5px;
  font-family: 'Bebas Neue';
  font-style: normal;
  color: #222831;
  cursor: pointer;
  font-weight: 500;
}
.profileNav p:hover {
  background-color: #ebebeb;
}


.buttonDiv {
  margin: 10px 0px;
  max-width: 100px;
}
.buttonDiv .primary {
  cursor: pointer;
  background: #222831;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #000000;
  padding: 5px 20px;
}
.buttonDiv .primary:hover {
  background: #3d3e3f;
}
.buttonDiv .cancel {
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
  color: rgb(5, 5, 5);
  border: 1px solid #222831;
  padding: 5px 20px;

}
.buttonDiv .cancel:hover {
  background: #ececec;
}

/* address style  */
.addressContainer .favTitle{
  margin-top: 0px;
  margin-bottom: 15px;
  font-family: 'Bebas Neue';
}
.checkbox2 {
  padding: 10px;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* .checkbox2detailsContainer .checkdetails{
  display: flex;
  margin-right: 70px;
  overflow-wrap: break-word;
} */
.checkbox2 .checkbox2detailsContainer .editOption {
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
}
.checkbox2 .editOption h4{
  margin: 0px;
}


.signUpCover{
    background-color: red;
    min-height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-position: center;
    object-fit: cover;

}
.signUpContainer .signupHeading, .form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signupHeading{
margin-bottom: 20px;
}

.signupHeading h1{
    font-weight: bold;
    color: whitesmoke;
    margin: 0px;
}
.signupHeading h2{
    font-weight: bold;
    margin: 0px;
    margin-top: 5px;
    color: whitesmoke;
}
.signUpContainer .form input{
    border: none;
    padding-left: 10px;
    padding-right: 10px;
    background-color: whitesmoke;
    border-radius: 10px;
    width: 300px;
    height: 50px;
}
.signUpContainer .form .btn-regular{
    border: none;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fee508;
    border-radius: 10px;
    font-weight: bold;
    font-family: 'Bebas Neue';
    font-size: large;
    width: 320px;
    height: 50px;
}
.smallText{
    color: whitesmoke
}
.lebelText{
    color: whitesmoke
}
.txt{
    display: flex;
    justify-content: center;
    color: wheat;
    align-items: center;
}
.signUpContainer .txt .regTolog{
    color: #fee508;
    text-decoration: none;
    margin-left: 10px;
}
.signUpContainer .txt .regTolog:hover{
    text-decoration: underline;
    color: #fced6b;
}

.signUpContainer .form .btn-regular:hover{
    border: 2px solid #837b36;
    background-color: #fced6b;
    color: black;
}
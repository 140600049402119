.navbar {
	background-color: rgb(255, 255, 255);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 120vh;
	box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
}
.navbar .upperBar {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 75px;
}
.navbar .navbtn {
	background-color: rgb(255, 255, 255);
	color: #333;
	border: 1px solid #8f9d9f;
	border-radius: 8px;
	margin-bottom: 20px;
	padding: 0.5rem;
	padding-bottom: 0.3rem;
}

.navbar img {
	height: 40px;
	width: 40px;
	border-radius: 100%;
	object-fit: cover;
}

.navbar .logout {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

@media screen and (max-width: 999px) {
	.navbar {
		display: none;
	}
}

.favourite {
  background-image: url("../../assets/images/background.jpg");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: auto;
  padding: 10px;
}
.favourite .item {
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
  background-color: whitesmoke;
  border-radius: 8px;
  min-height: 352px;
	height: 100%;
}
.favourite .item .details {
	padding: 0px 10px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 212px;
}
.favourite .favTitle {
  font-family: 'Bebas Neue';
  font-weight: 400;
  margin: 0px;
  padding-bottom: 10px;

}
.favourite .favImg {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* singleItme */
.favourite .singleItme {
  padding: 1%;
  border-radius: 10px;
}

.favourite .favDetails {
  height: 100%;
  margin: auto 0;
  padding: 10px; 
}
.favourite .favDetails p{
  margin: 0px;
  margin-bottom: 5px;

}

.favourite .favDetails .small {
  font-size: 14px;
  font-weight: bold;
}
.favourite .favDetails .midium {
  font-size: 20px;
  font-weight: bold;
}

.favourite .favDetails .b {
  padding: 4px 10px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  border: none;
}
@media only screen and (max-width: 600px) {
  .favourite {
    padding: 5px;
  }
}
.historyDiv{
  width: 100%;
  display: flex; 
  align-items: center;
  justify-content: center;
}
.historyBtn{
  background-color: white;
  font-weight: bold;
  border-radius: 8px;
  font-size: 20px;
  padding: 10px;
  margin: 15px 0;
  text-decoration: none;
  color: black;
  border: 1.5px solid;
}
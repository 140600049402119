.pizza-d{
    display: block;
}
.pizza-m{
    display: none;
}

@media only screen and (max-width: 991px) {
    .pizza-d{
        display: none;
    }
    .pizza-m{
        display: block;
    }
}
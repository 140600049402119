.info-btn{
    background-color: #5557c4 !important;
}

.info-btn:hover{
    background-color: rgb(33, 34, 126) !important;
}

.cancelOrder-btn{
    background-color: rgb(167, 69, 66) !important;
}

.cancelOrder-btn:hover{
    background-color: rgb(189, 6, 6) !important;
}